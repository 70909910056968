import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import BaseBanner from 'components/banners/base-banner';

const ALT = 'Total Loyalty, find the right subscription for you.';

const SubscriptionBanner = () => {
  const data = useStaticQuery(graphql`
   query {
     lg: file(relativePath: { eq: "subscription/subscription_large_deskop.png" }) {
       childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, formats: [AUTO,WEBP], placeholder:TRACED_SVG)
        }
     }
     md: file(relativePath: { eq: "subscription/subscription_desktop.png" }) {
       childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, formats: [AUTO,WEBP], placeholder:TRACED_SVG)
        }
     }
     sm: file(relativePath: { eq: "subscription/subscription_desktop.png" }) {
       childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, formats: [AUTO,WEBP], placeholder:TRACED_SVG)
        }
     }
     xs: file(relativePath: { eq: "subscription/subscription_mobile.png" }) {
       childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, formats: [AUTO,WEBP], placeholder:TRACED_SVG)
        }
     }
   }
 `);

  return (<BaseBanner alt={ALT} data={data}/>);
};

export default SubscriptionBanner;
